/* INITIALIZERS + DEFAULTS
 * ============================== */
@use "sass:math";

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

//BREAKPOINTS
$minSM: 600px;
$minMD: 960px;
$minLG: 1280px;
$minXL: 1920px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  // --color-bg: #030408;
  --color-text: #000;
  --color-subtext: #fff;
  --color-accent: #0a1147;
}

#App {
  overflow-x: auto;
}

html {
  font-size: 65%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background: rgb(83, 83, 83);
  background: radial-gradient(
    circle,
    rgba(83, 83, 83, 1) 0%,
    rgba(22, 22, 25, 1) 100%
  );
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.06px;
  color: #263238;
}

/* BASE APP
    * ============================== */
.App__header {
  color: white;
  // background-color: rgba(92, 92, 92, 0.3);
  height: 400px;
  display: flex;
  justify-content: center;
  // background-color: #32e3f4;

  .carousel-image {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  .slider-wrapper {
    height: 100%;
  }

  .carousel-root {
    height: 100%;

    .carousel-slider {
      height: 100%;
    }
  }

  @media screen and (max-width: 375px) {
    padding: 0 1rem;
  }
}

@media (min-width: 800px) {
  .floating {
    position: fixed;
    animation-name: floating;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 20px;
    margin-top: 13px;
    z-index: 50000;

    :hover {
      cursor: pointer;
    }
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }
}
@media (max-width: 799px) {
  .floating {
    display: none;
  }
}

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 30px 5px;
  text-align: center;
}

.App__title-text {
  margin: 0;
  padding-top: 40px;
  margin-bottom: 20px;
  font-family: "Bebas Neue", cursive !important;
  font-size: 42px;
  text-align: center;
  letter-spacing: 2.5px;
  color: var(--color-text);

  @media (min-width: $minSM) {
    font-size: 31px;
  }

  @media (min-width: $minMD) {
    font-size: 34px;
  }

  @media (min-width: $minLG) {
    font-size: 42px;
  }

  @media screen and (max-width: 599px) {
    font-size: 28px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 375px) {
    letter-spacing: 1.5px;
  }
}

.product-sort-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.sort-buttons {
  width: 150px;
  margin: 25px !important;
  min-width: auto !important;

  @media (max-width: 660px) {
    height: 35px;
    margin: 5px !important;
  }
}

.Products-wrapper {
  margin-top: 3em;

  padding: 0 10px;
}

.Products-wrapper__inner {
  max-width: 1350px;
  margin: auto;
  margin-bottom: 20px;
}

.App__view-cart-wrapper {
  display: none;
  position: fixed;
  top: 20px;
  right: 10px;

  @media (min-width: $minSM) {
    display: block;
    top: 15px;
  }

  @media (min-width: 1080px) {
    top: 20px;
  }
}

.App__view-cart-wrapper-mobile {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 30px;

  @media (min-width: $minSM) {
    display: none;
  }
}

.App__view-cart {
  font-size: 40px;
  border: none;
  background: none;
  cursor: pointer;
  color: grey;
}

.App__view-cart.hide {
  display: none;
}

.button {
  background-color: #2752ff;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #222222;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
    * ============================== */
.Cart {
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;

  @media (min-width: $minSM) {
    width: 350px;
    border-left: 2px solid #e5e5e5;
  }
}

.Cart__close {
  position: absolute;
  right: 9px;
  font-size: 35px;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;

  top: 10px;

  @media (min-width: $minSM) {
    top: 15px;
  }

  @media (min-width: 1080px) {
    top: 20px;
  }
}

.Cart__title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.06px;
  color: #263238;

  @media (min-width: $minLG) {
    font-size: 22px;
  }
}

.Cart__icon {
  font-size: 40px !important;
  color: black;
  stroke: #f5f5f5;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background: #f5f5f5;

  height: 60px;

  @media (min-width: $minSM) {
    height: 70px;
  }

  @media (min-width: 1080px) {
    height: 80px;
  }
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
  height: calc(100% - 148px - 60px - 10px);
  overflow-y: auto;

  @media (min-width: $minSM) {
    height: calc(100% - 85px - 70px - 10px);
  }

  @media (min-width: 1080px) {
    height: calc(100% - 85px - 80px - 10px);
  }
}

.Cart__footer {
  padding: 0 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
  background: #f5f5f5;
  height: 148px;

  @media (min-width: $minSM) {
    height: 85px;
  }
}

.Cart__checkout {
  margin-top: 20px !important;
  display: block;
  width: 100%;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 11px;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}

/* LINE ITEMS
    * ============================== */
.Line-items {
  margin-bottom: 20px;
  backface-visibility: visible;
  min-height: 78px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Line-item__img {
  width: 78px;
  height: 78px;

  img {
    height: 100%;
    width: 100%;
    border: 1px solid #b0bec5;
    border-radius: 4px;
    object-fit: cover;
  }
}

.Line-item__content {
  width: calc(100% - 78px);
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__variant-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.06px;
  color: #546e7a;
  margin-bottom: 7px;
}

.Line-item__title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.06px;
  color: #263238;
  margin-right: 30px;

  @media (max-width: 300px) {
    width: 100%;
    margin-top: 20px;
  }
}

.Line-item__price {
  float: right;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.06px;
  color: #546e7a;

  @media (max-width: 300px) {
    width: 100%;
    margin-top: 5px;
  }
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
  min-width: 90px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
  padding-right: 0;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
    * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
  margin-top: 3%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease-in-out;
  // background-color: rgba(226, 226, 226, 0.6);

  .Product__Image {
    width: 100%;
    align-self: center;
  }
  .Product__ImageContainer {
    width: 100% !important;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Product:hover {
  // background-color: rgba(50, 199, 244, 0.3);
  transform: scale(1.1) translateY(-25px);
  // border: 1px solid #ae5922;
  border-radius: 25px;
  cursor: default;

  @media (max-width: 768px) {
    transform: none;
  }

  .Product__OpenDetailsButton {
    display: block !important;
    position: absolute !important;
    bottom: 0px !important;
  }
}

.Product__OpenDetailsButton:hover {
  background-color: #060b30 !important;
}

.Product__OpenDetailsButton {
  display: none !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  width: 175px !important;
  height: 50px !important;
  background-color: var(--color-accent) !important;
  cursor: pointer !important;
  position: relative !important;
  overflow: hidden !important;
  color: var(--color-subtext) !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  text-align: center !important;
  letter-spacing: -0.1px !important;

  @media (max-width: 375px) {
    width: 125px !important;
    height: 35px !important;
    font-size: 14px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  @media (max-width: 320px) {
    width: 130px !important;
    font-size: 13px !important;
  }
}

.ProductOptions__Container {
  width: 100% !important;
  height: 100%;
  padding: 10px;

  @media (min-width: $minSM) {
    padding: 15px;
  }

  @media (min-width: $minMD) {
    padding: 0 20px 20px;
    min-width: 585px;
    overflow-x: hidden;
  }

  > * {
    padding: 10px;
  }
}

.ProductOptions__Carousel-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductOptions__Carousel-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.ProductOptions__Carousel {
  width: 100%;
  display: flex;

  @media (min-width: $minMD) {
    width: 440px;
    height: 440px;
  }

  img {
    object-fit: cover;
    min-height: 90vw;

    @media (min-width: $minSM) {
      min-height: 415px;
    }
  }
}

.ProductOptions__Carousel .carousel.carousel-slider .control-arrow,
.carousel .control-arrow {
  opacity: 0;
}

.ProductOptions__Carousel .carousel .slide {
  background: none;
}

.ProductOptions__Carousel .carousel .control-prev.control-arrow:before {
  border-right: 8px solid var(--color-text);
}

.ProductOptions__Carousel .carousel .control-next.control-arrow:before {
  border-left: 8px solid var(--color-text);
}

.ProductOptions__Carousel .carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0);
}

.ProductOptions__Carousel .carousel .control-arrow:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.ProductOptions__Carousel button.control-arrow.control-next {
  @media (min-width: $minMD) {
    padding-left: 30%;
  }
}

.ProductOptions__Carousel button.control-arrow.control-prev {
  @media (min-width: $minMD) {
    padding-right: 30%;
  }
}

.Product__ImageContainer {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.Product__ImageContainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.Product__Image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.ProductOptions__Dialog {
  position: absolute;
}

.MuiPaper-root {
  background: rgb(83, 83, 83);
  background: radial-gradient(
    circle,
    rgba(83, 83, 83, 1) 0%,
    rgba(22, 22, 25, 1) 100%
  );
}

.ProductOptions__Close {
  position: fixed !important;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  z-index: 1000;

  @media (min-width: $minMD) {
    position: relative !important;
    right: -95%;
  }
}

.Product__title {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: lighter;
  font-size: 20px !important;
  text-align: center;
  margin: 14px 0 0 0;
  color: var(--color-text);

  @media (min-width: $minSM) {
    font-size: 23px;
  }

  @media (min-width: $minMD) {
    font-size: 24px;
  }

  @media (min-width: $minLG) {
    font-size: 27px;
  }
}

.Product__subtitle {
  font-family: "Bebas Neue", cursive;
  font-weight: lighter !important;
  text-align: center;
  margin: 14px 0 0 0;
  font-size: 25px;
  letter-spacing: 1px;
  color: var(--color-text);

  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
}

.Product__price {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -1.02857px;
  margin: 0;
  color: var(--color-text);

  @media (min-width: $minSM) {
    font-size: 21px;
  }

  @media (min-width: $minMD) {
    font-size: 22px;
  }

  @media (min-width: $minLG) {
    font-size: 24px;
  }

  @media screen and (max-width: 375px) {
    font-size: 18px;
  }
}

.Product__option {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 45px;

  @media (min-width: $minMD) {
    height: 60px;
    font-size: 17px !important;
  }
}

.Product__option-label {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.06px;
  color: var(--color-text);

  @media (min-width: $minSM) {
    font-size: 17px;
  }

  @media (min-width: $minMD) {
    font-size: 18px;
  }

  @media (min-width: $minLG) {
    font-size: 19px;
  }
}

.Product__option-select {
  width: 70% !important;
  height: 44px;
  color: black !important;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

@keyframes load-anim {
  0% {
    background-color: rgba(0, 0, 0, 0.05);
  }
  30% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.loading_block {
  height: 50vw;
  width: 100%;

  @media (min-width: $minMD) {
    height: 30vw;
  }

  @media (min-width: $minMD) {
    height: 24vw;
  }

  @media (min-width: $minLG) {
    height: 19vw;
  }

  -webkit-animation: load-anim 0.7s infinite;
  -moz-animation: load-anim 0.7s infinite;
  -o-animation: load-anim 0.7s infinite;
  animation: load-anim 0.7s infinite;
}

.grid-countdown__main {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: baseline;
  border-radius: 6px;
  padding: 2px 2px 2px 7px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.3;

  @media (min-width: $minSM) {
    font-size: 16px;
    padding: 2px 2px 2px 10px;
    letter-spacing: 2px;
  }

  @media (min-width: $minMD) {
    font-size: 16px;
  }

  @media (min-width: $minLG) {
    padding: 2px 2px 2px 12px;
    font-size: 17px;
  }
}

.grid-countdown__timer {
  width: max-content;
  margin-left: 8px;
  padding: 0px 6px;
  border-radius: 5px;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 13px;

  @media (min-width: $minSM) {
    font-size: 16px;
    padding: 0px 14px;
    margin-left: 10px;
    letter-spacing: 2px;
  }

  @media (min-width: $minMD) {
    padding: 2px 14px;
    font-size: 16px;
  }

  @media (min-width: $minLG) {
    margin-left: 12px;
    font-size: 17px;
  }
}

.modal-countdown__main {
  margin-top: 20px;
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  background: #fff;
  border-radius: 6px;
  padding: 2px 2px 2px 2px;
  span {
    text-align: center;
  }

  span + span {
    margin-left: 5px;
  }

  @media (min-width: $minMD) {
    font-size: 26px;
  }

  @media (min-width: $minLG) {
    margin-top: 30px;
    font-size: 28px;
  }
}

.modal-countdown__time-description {
  font-size: 16px;

  @media (min-width: $minMD) {
    font-size: 18px;
  }

  @media (min-width: $minLG) {
    font-size: 20px;
  }
}

/* SINGLE PRODUCT VIEW
  * ============================== */

.Images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* width: 20%; */
}

.homeButton {
  position: absolute;
  top: 10px;
  left: 50%;
}

.Product__title2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-text);

  @media (min-width: $minMD) {
    font-size: 26px;
  }

  @media (min-width: $minLG) {
    font-size: 28px;
  }
}

.Product__description {
  width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: var(--color-text);
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;

  @media (min-width: $minMD) {
    font-size: 19px;

    min-width: 400px;
    width: 100%;
  }

  p {
    margin: 10px 0 10px 0;
  }
}

.Product__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

/* CUSTOMER AUTH
 * ============================== */

.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

/*  FOOTER
 * ============================== */
.Footer_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  // padding: 22px 10px;
  margin-top: 100px;
  width: 100%;
}

.Footer-Social-Info-Section {
  width: 600px;
  text-align: center;
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 425px) {
    width: 100%;
  }

  @media screen and (max-width: 320px) {
    width: 300px;
  }
}

.Footer-Social-Text {
  margin-top: 40px;
  margin-bottom: 30px;

  h1 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold !important;
    font-size: 32px !important;
    line-height: 41px !important;
    text-align: center;
    letter-spacing: -1.02857px;
    color: var(--color-text);
    text-decoration: underline 1px var(--color-text);
    text-underline-offset: 10px;
  }

  h3 {
    font-size: 1.5em;
  }
}

#footer-social-links {
  list-style-type: none;
  padding: 0 !important;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;

  @media screen and (max-width: 525px) {
    width: 60%;
    font-size: 8px;
  }
}

.social-link {
  color: var(--color-text);

  i:hover {
    color: var(--color-accent);
  }
}

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.Footer_top-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--color-text);
}

.Footer_top-link {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: var(--color-text);
  text-decoration: none;

  @media (min-width: $minSM) {
    font-size: 20px;
  }

  @media (min-width: $minMD) {
    font-size: 22px;
  }

  @media (min-width: $minLG) {
    font-size: 24px;
  }
}

.Footer_top-link:not(:last-child) {
  margin-right: 20px;
}

.Footer_top-link:not(:first-child) {
  margin-left: 20px;
}

.Footer_bottom {
  width: 100%;
  min-height: 80px;
  background: none;
  box-shadow: 0 1px 10px rgb(0, 0, math.div(0, 0.2));
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: var(--color-text);

  @media (min-width: $minMD) {
    font-size: 16px;
  }

  @media (min-width: $minLG) {
    font-size: 18px;
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  :not(:first-child) {
    margin-left: 10px;
  }
}

.Footer_bottom-link {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: var(--color-text);
  text-decoration: none;

  @media (min-width: $minMD) {
    font-size: 16px;
  }

  @media (min-width: $minLG) {
    font-size: 18px;
  }
}

.NotFound_Title {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 41px;
  text-align: center;
  letter-spacing: -1.02857px;
  color: #263238;
  text-decoration: underline;
  text-underline-offset: 20px;
}
